.footer {
  padding: 20px 0;
  background-color: #333;
}

.footer-text {
  font-size: 0.9rem;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
}