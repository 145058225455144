.app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: #1a1a1a;
  margin: 0;
  padding: 0;
  background: #fff;
  text-align: center;
  position: relative;
  scrollbar-width: 0;
}

h1 {
  color: #000;
  font-size: 24px;
}

p {
  color: #666;
  font-size: 16px;
}

main .section:not(:last-child) {
  margin-bottom: 40px;
  border-bottom: 1px solid #eaeaea;
}

a {
  color: #007aff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.console-padding {
  padding-bottom: 212px;
}

section {
  text-align: center;
  padding: 20px;
}

.app-header h1 {
  font-size: 36px;
  margin-bottom: 10px;
}