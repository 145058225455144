.slideshow_container {
  position: relative;
  margin: auto;
}

.slideshow_item {
  width: 100%;
  height: 100%;
}

.project-img {
  max-width: 75%;
  margin: auto;
}

.slideshow_item a img {
  max-width: 100%;
  height: auto;
  margin: auto;
}

.arrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin: auto;
  transform: translate(0%, -50%);
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 3px;
  user-select: none;
  opacity: 0.8;
}

.left {
  left: 0;
}

.right {
  right: 0;
}