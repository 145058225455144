.console {
  background-color: #292a30;
  color: white;
  font-family: 'Courier New', Courier, monospace;
  font-size: medium;
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  /* Use flex to make .output take all available space */
  display: flex;
  flex-direction: column;
}

.output {
  white-space: pre-wrap;
  overflow-y: scroll;
  flex: 1;
  /* Take up remaining space */
  text-align: left;
  scrollbar-width: thin;
  scrollbar-color: #9a9b9d #35363b;
  word-wrap: break-word;
}

.options {
  display: flex;
}

.options button {
  background: none;
  color: inherit;
  border: none;
  border-radius: 5px;
  padding: 0 4px;
  font-size: small;
}

.options .radio-selected {
  background-color: #263f5a;
}

.options button:hover {
  background: #393a40;
  color: inherit;
}

.options .radio-selected:hover {
  background-color: #263f5a;
}

.options input {
  background-color: #323232;
  border-radius: 5px;
  padding-left: 10px;
  outline: 0.1px solid #505153;
  border: none;
  color: white;
}

.options input:focus {
  background-color: #1e1e1e;
}

.console-button {
  height: 100%;
}

.console-icon {
  transition: fill 0.3s;
  transition: stroke 0.3s;
  width: 16px;
  height: 16px;
}

.console p {
  color: white;
  font-family: 'Courier New', Courier, monospace;
  font-size: medium;
  margin: 0;
  text-align: left;
}

.floating {
  position: absolute;
  bottom: 0;
  left: 0;
  padding-left: 10px;
  padding-bottom: 5px;
  background-color: #292a30;
}

.spacer {
  flex: 1;
}

.input {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.input * {
  color: white;
  font-family: 'Courier New', Courier, monospace;
  font-size: medium;
}

.input input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
}

.input input:focus {
  outline: none;
}

.log {
  word-break: break-all;
}

.output::-webkit-scrollbar {
  width: 8px;
}

.output::-webkit-scrollbar-thumb {
  background-color: #9a9b9d;
  border-radius: 4px;
}

.output::-webkit-scrollbar-track {
  background-color: #35363b;
}

.popover {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgba(28, 28, 30, 0.95);
  color: white;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 28px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  z-index: 100;
  max-width: 300px;
}

.popover:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 12px;
  margin-left: -5px;
  border: 5px solid transparent;
  border-top-color: rgba(28, 28, 30, 0.95);
}

.popover>*:not(:last-child) {
  margin-bottom: 8px;
}

.popover-divider {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin: 4px 0;
}

.popover-button {
  background-color: rgba(0, 122, 255, 1);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
}

.popover-button:hover {
  background-color: rgba(0, 122, 255, 0.8);
}

.copyright {
  font-size: 8px;
  align-self: flex-start;
  line-height: 16px;
  margin-right: 8px;
}

.console-contents {
  display: flex;
  width: 100%;
  height: 100%;
}

.navigation {
  width: calc(50% - 1px);
}

.divider {
  width: 2px;
  height: 100%;
  background-color: black;
}

.dropdown-inline {
  display: flex;
}

.dropdown-inline button {
  color: white;
  background: none;
  border: none;
  cursor: pointer;
}

.logs-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.content-container {
  display: flex;
  height: 100%;
  width: 100%;
}