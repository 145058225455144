.contact-section {
  padding: 60px 0;
  background-color: #f5f5f5;
}

.contact-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.contact-email {
  font-size: 1.2rem;
  font-weight: 500;
}

.contact-social {
  display: flex;
  gap: 15px;
}

#contact a {
  color: black;
}

.social-icon {
  width: 30px;
  height: 30px;
}