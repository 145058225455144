.experience-section {
    margin-bottom: 40px;
}

.experience-card {
    text-align: left;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.experience-card h3 {
    font-size: 20px;
    margin-bottom: 10px;
}

.experience-card:not(:last-child) {
    margin-bottom: 20px;
}