.projects-section {
  margin-bottom: 40px;
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.project-card {
  text-align: left;
  width: 300px;
  margin: 20px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.project-card h3 {
  padding: 0;
  margin: 0;
}

.project-card img, .project-card video {
  border-radius: 8px;
  margin-bottom: 10px;
}